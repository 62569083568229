
const apiURL = 'https://script.google.com/macros/s/AKfycbzShYiVfzlQGliAhrQ5leLKtDZZ0ILd-j9JVFhXKxmEbiOhVq6V8qQDO29kTAZnum397Q/exec';


async function importantTopicsNewsLoadData(apiURL) {

  function createCatButton(label, url) {
    const catBtn = document.createElement('a');
    const catBtnSpan = document.createElement('span');
    catBtn.className = 'importantNewsCatBtn';
    catBtn.href = url;
    catBtn.appendChild(catBtnSpan);
    catBtnSpan.textContent = label+'の一覧へ';
    return catBtn;
  }

  const importantNewsSection = document.querySelector('.importantNews-section');

  const importantNewsList = document.querySelector('.importantNewsList');
  const importantNewsBody = document.querySelector('.importantNews-body');
  const importantNewsLoader = document.querySelector('.importantNewsList-loader');

  const importantNewsCatBtnList = document.createElement('div');
  importantNewsCatBtnList.className = 'importantNewsCatBtnList';
  const importantNewsCatBtn1 = createCatButton('通知', 'important/?cat=通知');
  const importantNewsCatBtn2 = createCatButton('化学物質の管理', 'important/?cat=化学物質の管理');
  const importantNewsCatBtn3 = createCatButton('学生正課中事故', 'important/?cat=学生正課中事故');
  const importantNewsCatBtn4 = createCatButton('その他', 'important/?cat=その他');


  //ボタン作る
  importantNewsCatBtnList.appendChild(importantNewsCatBtn1);
  importantNewsCatBtnList.appendChild(importantNewsCatBtn2);
  importantNewsCatBtnList.appendChild(importantNewsCatBtn3);
  importantNewsCatBtnList.appendChild(importantNewsCatBtn4);
  importantNewsBody.appendChild(importantNewsCatBtnList);

  //トピックス情報
  const topicsNewsSection = document.querySelector('.topicsNews-section');
  const topicsNewsList = document.querySelector('.topicsNewsList');
  const topicsNewsBody = document.querySelector('.topicsNews-body');
  const topicsNewsLoader = document.querySelector('.topicsNewsList-loader');

  // データをロードする前にローダー画像を表示
  importantNewsLoader.style.display = 'flex';
  topicsNewsLoader.style.display = 'flex';

  try {
    const response = await fetch(apiURL);
    const data = await response.json();
  
    // 'トップ重要なお知らせ'のデータを取得
    const sheetNames = ['トップ重要なお知らせ', 'トップトピックス'];
    const targetNewsLists = {
      'トップ重要なお知らせ': importantNewsList,
      'トップトピックス': topicsNewsList
    };
    sheetNames.forEach(sheetName => {
      const sheetData = data[sheetName];
      const targetNewsList = targetNewsLists[sheetName];



      if (sheetName === 'トップ重要なお知らせ') {

        // ローダー画像を非表示に
    importantNewsLoader.remove();
  } else if (sheetName === 'トップトピックス') {
       //ローダー画像を非表示に
    topicsNewsLoader.remove();
  }
    // データが1件もない場合の処理
    if (sheetData.length === 0) {
      targetNewsList.remove();
      let noDataElement = document.createElement('p');
      noDataElement.className = 'noDataTxt';
      if (sheetName === 'トップ重要なお知らせ') {
        noDataElement.textContent = '現在重要なお知らせはありません。';
        importantNewsBody.appendChild(noDataElement);
      } else if (sheetName === 'トップトピックス') {

        noDataElement.textContent = '現在トピックスはありません。';
        topicsNewsBody.appendChild(noDataElement);
      }
     
      return;
    }
    sheetData.forEach(entry => {
const labelLinkFlag = false;
      const listElement = document.createElement('li');
      listElement.className = 'newsList-item';
      const dateElement = document.createElement('div');
      dateElement.className = 'newsList-date';
      const labelElement = document.createElement('div');
      labelElement.className = 'newsList-label';
      const linkElement = document.createElement('a');
      linkElement.className = 'newsList-link';
      const contentElement = document.createElement('div');
      contentElement.className = 'newsList-content';
      const hostname = location.hostname;


  // textStatusに基づいて追加のクラスを設定
  const statusClasses = {
    '太字': 'status-bold',
    '赤字': 'status-red',
    '通常': 'status-normal'
  };

  


        if (statusClasses[entry.textStatus]) {
          contentElement.classList.add(statusClasses[entry.textStatus]);
        }
        if (entry.content) {
              //内容があるならlist要素を作成
            targetNewsList.appendChild(listElement);
            if (entry.date) {
              const dateTime = new Date(entry.date);
              var y = dateTime.getFullYear();
              var m = ('00' + (dateTime.getMonth()+1)).slice(-2);
              var d = ('00' + (dateTime.getDate())).slice(-2);
              var date = y + '/' + m + '/' + d;
              dateElement.textContent = date;
              listElement.appendChild(dateElement);
            }
            if (entry.label) {
              if (entry.label !== "なし") {
                //ラベルがなし以外なら
                  const catLinkElement = document.createElement('a');
                  catLinkElement.textContent = entry.label;
                  catLinkElement.href = `important/?cat=${entry.label}`;
                  labelElement.appendChild(catLinkElement);
                  listElement.appendChild(labelElement);
 
                }else{
                  //ラベルがなしの場合はラベルなし

                }
            }
            if (entry.content) {
              if (entry.linkUrl) {
                //リンクあるなら
                linkElement.href = entry.linkUrl;
                if(!entry.linkUrl.includes(hostname)) {
                  linkElement.setAttribute('target','_blank');
                  linkElement.setAttribute('rel','noreferrer');
                }
                listElement.appendChild(contentElement);
                contentElement.appendChild(linkElement);
                const formattedContent = entry.content.replace(/\n/g, '<br>');
                linkElement.innerHTML = formattedContent;
                
              } else {
                //重要なお知らせの場合
                if (entry.label) {
                  //ラベルはそんざいする
                if (entry.label !== "なし") {
                   //テキストリンクなしラベル「なし」じゃないのがある
                  linkElement.href = `important/?cat=${entry.label}`
                  const formattedContent = entry.content.replace(/\n/g, '<br>');
                  linkElement.innerHTML = formattedContent;
                  contentElement.appendChild(linkElement);
                  listElement.appendChild(contentElement);
                }else{
                  //テキストリンクなしラベル「なし」
                  const formattedContent = entry.content.replace(/\n/g, '<br>');
                  contentElement.innerHTML = formattedContent;
                  listElement.appendChild(contentElement);
                }
              }else{
                //ラベルがない
                const formattedContent = entry.content.replace(/\n/g, '<br>');
                contentElement.innerHTML = formattedContent;
                listElement.appendChild(contentElement);
              }

              }

             }
       }


      });
    });





  } catch (error) {
    importantNewsList.remove();
    importantNewsCatBtnList.remove();
    topicsNewsList.remove();
    const errorDataElement1 = document.createElement('p');
    const errorDataElement2 = document.createElement('p');
    errorDataElement1.className = 'errorDataTxt';
    errorDataElement2.className = 'errorDataTxt';
      errorDataElement1.textContent = '申し訳ございません。読み込みエラーが発生しました。';
      importantNewsBody.appendChild(errorDataElement1);

      errorDataElement1.textContent = '申し訳ございません。読み込みエラーが発生しました。';
      topicsNewsBody.appendChild(errorDataElement2);


  }

}

importantTopicsNewsLoadData(apiURL);

